<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle> </mainTitle>

          <section class="edp-backend-conditionSearch">
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendRank.rankName") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="100"
                  :placeholder="$t('edpBackendCommon.placeholder')"
                  v-model="searchName"
                  clearable
                />
              </div>
            </div>

            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendRank.rankStatus") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-select
                  v-model="searchParams.isEnabled"
                  :placeholder="$t('edpBackendCommon.select')"
                >
                  <el-option
                    value="true"
                    :label="$t('edpBackendRank.statusYes')"
                  ></el-option>
                  <el-option
                    value="false"
                    :label="$t('edpBackendRank.statusNp')"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div
              class="edp-backend-conditionSearch-square conditionSearch-square-last"
            >
              <el-button size="small" @click="clearSearchParams">{{
                $t("edpBackendCommon.clear")
              }}</el-button>
              <el-button size="small" type="primary" @click="pageInit(1)">{{
                $t("edpBackendCommon.search")
              }}</el-button>
            </div>
          </section>

          <section class="edp-backend-common-table">
            <div class="table-headControlLine">
              <div>
                <el-button type="primary" size="small" @click="createRow">
                  {{ $t("edpBackendCommon.add") }}</el-button
                >
              </div>
            </div>

            <el-table
              stripe
              :data="pageList"
              style="width: 100%"
              v-loading="loading"
            >
              <el-table-column
                prop="id"
                label="ID"
                width="100"
              ></el-table-column>

              <el-table-column
                prop="rankCname"
                :label="$t('edpBackendRank.dialogCname')"
                width="200"
              ></el-table-column>

              <el-table-column
                prop="rankEname"
                :label="$t('edpBackendRank.dialogEname')"
                width="200"
              ></el-table-column>

              <el-table-column :label="$t('edpBackendRank.dialogRankSorted')">
                <template slot-scope="scope">
                  {{
                    scope.row.rankSorted || scope.row.rankSorted == 0
                      ? scope.row.rankSorted
                      : "--"
                  }}
                </template>
              </el-table-column>

              <el-table-column
                :label="$t('edpBackendRank.rankStatus')"
                width="100"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.isEnabled
                      ? $t("edpBackendRank.statusYes")
                      : $t("edpBackendRank.statusNp")
                  }}
                </template>
              </el-table-column>

              <el-table-column :label="$t('edpBackendRank.updateTime')">
                <template slot-scope="scope">
                  {{ scope.row.modifyTime ? scope.row.modifyTime : "--" }}
                </template>
              </el-table-column>

              <el-table-column
                :label="$t('edpBackendCommon.operate')"
                width="120"
              >
                <template slot-scope="scope">
                  <el-button type="text" @click="updateRow(scope.row)">{{
                    $t("edpBackendCommon.edit")
                  }}</el-button>

                  <el-button type="text" @click="deleteRow(scope.row)">{{
                    $t("edpBackendCommon.delete")
                  }}</el-button>
                </template>
              </el-table-column>
            </el-table>

            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="pageTotal"
            >
            </el-pagination>
          </section>
        </div>
      </section>
      <!-- content end -->
    </section>

    <el-dialog
      class="edp-backend-dialogEl rankback-dialog"
      v-if="dialogVisible"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="660px"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item
          :label="$t('edpBackendRank.dialogCname')"
          prop="rankCname"
        >
          <el-input
            type="textarea"
            class="rankBack-textarea"
            v-model="form.rankCname"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('edpBackendRank.dialogEname')"
          prop="rankEname"
        >
          <el-input
            type="textarea"
            class="rankBack-textarea"
            v-model="form.rankEname"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item :label="$t('edpBackendRank.dialogApi')" prop="rankApiUrl">
          <el-input v-model="form.rankApiUrl" clearable></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('edpBackendRank.dialogRankType')"
          prop="rankType"
        >
          <el-select
            v-model="form.rankType"
            :placeholder="$t('edpBackendCommon.select')"
            clearable
          >
            <el-option
              v-for="(item, index) in typeList"
              :key="'rtype' + index"
              :value="item.value"
              :label="$i18n.locale === 'cn' ? item.cName : item.eName"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="$t('edpBackendRank.dialogRankCollation')"
          prop="rankCollation"
        >
          <el-select
            v-model="form.rankCollation"
            :placeholder="$t('edpBackendCommon.select')"
            clearable
          >
            <el-option
              value="asc"
              :label="$t('edpBackendRank.asc')"
            ></el-option>

            <el-option
              value="desc"
              :label="$t('edpBackendRank.desc')"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('edpBackendRank.dialogRankUnitCn')">
          <el-input v-model="form.rankCnUnit" clearable></el-input>
        </el-form-item>

        <el-form-item :label="$t('edpBackendRank.dialogRankUnitEn')">
          <el-input v-model="form.rankEnUnit" clearable></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('edpBackendRank.dialogRankCnTitle')"
          prop="rankCnTitle"
        >
          <el-input
            type="textarea"
            class="rankBack-textarea"
            v-model="form.rankCnTitle"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('edpBackendRank.dialogRankEnTitle')"
          prop="rankEnTitle"
        >
          <el-input
            type="textarea"
            class="rankBack-textarea"
            v-model="form.rankEnTitle"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item :label="$t('edpBackendRank.dialogRankCnContent')">
          <el-input
            type="textarea"
            class="rankBack-textarea"
            v-model="form.rankCnContent"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item :label="$t('edpBackendRank.dialogRankEnContent')">
          <el-input
            type="textarea"
            class="rankBack-textarea"
            v-model="form.rankEnContent"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item :label="$t('edpBackendRank.dialogIsEnabled')">
          <el-switch v-model="form.isEnabled"> </el-switch>
        </el-form-item>

        <el-form-item
          :label="$t('edpBackendRank.dialogRankRedBlack')"
          prop="rankRedBlack"
        >
          <el-select
            v-model="form.rankRedBlack"
            :placeholder="$t('edpBackendCommon.select')"
            clearable
          >
            <el-option
              :value="1"
              :label="$t('edpBackendRank.dialogRankRedBlack_red')"
            ></el-option>

            <el-option
              :value="2"
              :label="$t('edpBackendRank.dialogRankRedBlack_black')"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('edpBackendRank.dialogRankSorted')">
          <el-input v-model="form.rankSorted" clearable></el-input>

          <span class="edp-backend-formInfoTips">
            <i class="el-icon-info"></i>
            <span>{{ $t("edpBackendRank.dialogRankSortedWarning") }}</span>
          </span>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $t("edpBackendCommon.canale")
        }}</el-button>
        <el-button type="primary" @click="dialogVisible_submit">{{
          $t("edpBackendCommon.define")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "backend-rank",
  data() {
    return {
      pageList: [],
      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      loading: true,

      searchName: "",
      searchParams: {
        rankCname: "",
        rankEname: "",
        isEnabled: "",
      },

      dialogVisible: false,
      dialogTitle: "",

      form: {
        rankCname: "",
        rankEname: "",
        rankApiUrl: "",
        rankType: "",
        rankCollation: "",
        rankCnTitle: "",
        rankEnTitle: "",
        rankCnContent: "",
        rankEnContent: "",
        isEnabled: false,
        rankSorted: 0,
        rankCnUnit: "",
        rankEnUnit: "",
        rankRedBlack: "",
      },

      formOrigin: {},
      pageUpdateId: "",
      pageUpdateStatus: false,

      rules: {
        rankCname: [
          {
            required: true,
            message:
              this.$t("edpBackendCommon.placeholder") +
              "" +
              this.$t("edpBackendRank.dialogCname"),
            trigger: "blur",
          },
        ],
        rankEname: [
          {
            required: true,
            message:
              this.$t("edpBackendCommon.placeholder") +
              "" +
              this.$t("edpBackendRank.dialogEname"),
            trigger: "blur",
          },
        ],
        rankApiUrl: [
          {
            required: true,
            message:
              this.$t("edpBackendCommon.placeholder") +
              "" +
              this.$t("edpBackendRank.dialogApi"),
            trigger: "blur",
          },
        ],
        rankType: [
          {
            required: true,
            message:
              this.$t("edpBackendCommon.select") +
              "" +
              this.$t("edpBackendRank.dialogRankType"),
            trigger: "blur",
          },
        ],
        rankCollation: [
          {
            required: true,
            message:
              this.$t("edpBackendCommon.select") +
              "" +
              this.$t("edpBackendRank.dialogRankCollation"),
            trigger: "blur",
          },
        ],
        rankCnTitle: [
          {
            required: true,
            message:
              this.$t("edpBackendCommon.placeholder") +
              "" +
              this.$t("edpBackendRank.dialogRankCnTitle"),
            trigger: "blur",
          },
        ],
        rankEnTitle: [
          {
            required: true,
            message:
              this.$t("edpBackendCommon.placeholder") +
              "" +
              this.$t("edpBackendRank.dialogRankEnTitle"),
            trigger: "blur",
          },
        ],
        rankRedBlack: [
          {
            required: true,
            message:
              this.$t("edpBackendCommon.select") +
              "" +
              this.$t("edpBackendRank.dialogRankRedBlack"),
            trigger: "blur",
          },
        ],
      },

      typeList: [
        { value: 1, cName: "智慧办公", eName: "Smart Office" },
        { value: 2, cName: "人才发展", eName: "Talent Development" },
      ],
    };
  },
  methods: {
    ...mapActions({
      rankList: "backendRank/rankList",
      createRank: "backendRank/createRank",
      updateRank: "backendRank/updateRank",
      deleteRank: "backendRank/deleteRank",
    }),
    async pageInit(num) {
      this.loading = true;

      if (num) {
        this.currentPage = num;
      }

      this.searchParams.rankCname = this.searchName;
      this.searchParams.rankEname = this.searchName;

      let params = Object.assign(
        JSON.parse(JSON.stringify(this.searchParams)),
        {
          current: this.currentPage,
          size: this.pageSize,
        }
      );

      let res = await this.rankList(params);

      this.pageList = res.data.data.records;

      this.pageTotal = res.data.data.total;

      this.loading = false;
    },
    clearSearchParams() {
      for (var key in this.searchParams) {
        this.searchParams[key] = "";
      }

      this.pageInit(1);
    },
    handleCurrentChange(val) {
      this.pageInit(val);
    },
    createRow() {
      this.pageUpdateId = "";
      this.dialogTitle = this.$t("edpBackendCommon.add");

      this.form = JSON.parse(JSON.stringify(this.formOrigin));
      this.dialogVisible = true;

      this.$forceUpdate();
    },
    updateRow(data) {
      this.pageUpdateId = data.id;
      this.dialogTitle = this.$t("edpBackendCommon.edit");

      for (let key in this.form) {
        this.form[key] = data[key];
      }

      this.dialogVisible = true;

      this.$forceUpdate();
    },
    deleteRow(data) {
      this.$confirm(
        this.$t("edpBackendCommon.actionConfirm"),
        this.$t("edpBackendCommon.noticeTitle"),
        {
          confirmButtonText: this.$t("edpBackendCommon.define"),
          cancelButtonText: this.$t("edpBackendCommon.canale"),
        }
      ).then(async () => {
        let res = await this.deleteRank(data);

        if (res.data.success) {
          this.$message({
            type: "success",
            message: this.$t("edpBackendCommon.actionSuccess"),
          });

          this.pageInit();
        }
      });
    },
    dialogVisible_submit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.form)),
            res = {};

          if (!this.pageUpdateId) {
            res = await this.createRank(params);
          } else {
            params.id = this.pageUpdateId;
            res = await this.updateRank(params);
          }

          await this.$nextTick();

          if (res.data.success) {
            this.$message({
              type: "success",
              message: this.$t("edpBackendCommon.actionSuccess"),
            });

            this.dialogVisible = false;

            this.pageInit();
          }
        }
      });
    },
  },
  async mounted() {
    this.formOrigin = JSON.parse(JSON.stringify(this.form));
    this.pageInit(1);
  },
};
</script>

<style lang="scss">
.rankBack-textarea {
  height: 100px;

  textarea {
    height: 100%;
    resize: none;
  }
}
.rankback-dialog {
  .el-textarea {
    width: 340px;
  }
  .el-input {
    width: 340px;
  }
}
</style>
